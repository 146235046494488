import { useEffect } from 'react';

export const TermsOfService = () => {
  return (
    <div className="policy-container" style={{ backgroundColor: 'white', height: '100vh', width: '100%' }}>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=1941888f-60d8-4761-b934-9910af5be090"
        title="Terms of Service"
        style={{
          width: '100%',
          height: '100%',
          border: 'none'
        }}
      />
    </div>
  );
}; 
