import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer mt-4">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          {/* <Col size={12} sm={6} className="mt-4">
            <img src={logo} alt="Logo" />
          </Col> */}
          <Col size={12} sm={12} className="text-center pt-10">
            <div className="address text-slate-600">
              <p className="my-0 fw-bold">VANCITY VIRAL LABS INC</p>
              <p className="my-0"> Registered LTD Corporation In The Jurisdiction of BC Canada</p>
              <p className="my-0">Head Office Address: 777 Hornby Street, Suite 600, Vancouver, BC, Canada, V6Z 1S4</p>
              <p className="my-0">Corporation Number: BC1492222</p>
              <p className="my-0">Federal Business Number: 760843821BC0001</p>
              <p className="my-0">
                <a href="/privacy-policy" className="text-white">Privacy Policy</a> | <a href="/terms-of-service" className="text-white">Terms of Service</a> | <a href="/cookie-policy" className="text-white">Cookie Policy</a> | <a href="/eula" className="text-white">End User License Agreement</a> | <a href="/acceptable-use-policy" className="text-white">Acceptable Use Policy</a>
              </p>
            </div>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {/* <a href="#"><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a> */}
            </div>
            {/* <p>Copyright 2024. All Rights Reserved</p> */}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
