import logo from './logo.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { EndUserLicenseAgreement } from "./components/EndUserLicenseAgreement";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { CookiePolicy } from "./components/CookiePolicy";
import { AcceptableUsePolicy } from "./components/AcceptableUsePolicy";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={
          <>
            <NavBar />
            <Banner />
            {/* <Skills /> */}
            {/* <Projects /> */}
            <Contact />
            <Footer />
          </>
        } />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/eula" element={<EndUserLicenseAgreement />} />
        <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
      </Routes>
      </Router>
    </div>
    
  );
}

export default App;
