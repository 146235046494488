import { useEffect } from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="policy-container" style={{ backgroundColor: 'white', height: '100vh', width: '100%' }}>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=4dfc14fe-7171-4f35-8fa8-e59b625c214d"
        title="Privacy Policy"
        style={{
          width: '100%',
          height: '100%',
          border: 'none'
        }}
      />
    </div>
  );
}; 
